;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"udwI0DfTu_70G-fFB7hyp"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://f0dc634f1c72adbde86c552767f3d2d4@o401565.ingest.us.sentry.io/4508042241703936',
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
  enabled: process.env.NEXT_PUBLIC_ENVIRONMENT != undefined,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  release: process.env.npm_package_version,
});
